import { createWebHashHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    alias: "/schedules",
    name: "schedules",
    component: () => import("./components/SchedulesList")
  },
  {
    path: "/schedules/:id",
    name: "schedule-details",
    component: () => import("./components/Schedule")
  },
  {
    path: "/add",
    name: "add",
    component: () => import("./components/AddSchedule")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
